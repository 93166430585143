@font-face {
    font-family: IRANSans-web;
    font-style: normal;
    font-weight: bold;
    src: url('../fonts/persian/IRANSansWeb(FaNum)_Bold.eot');
    src: url('../fonts/persian/IRANSansWeb(FaNum)_Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/persian/IRANSansWeb(FaNum)_Bold.woff2') format('woff2'), url('../fonts/persian/IRANSansWeb(FaNum)_Bold.woff') format('woff'), url('../fonts/persian/IRANSansWeb(FaNum)_Bold.ttf') format('truetype');
}
@font-face {
    font-family: IRANSans-web;
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/persian/IRANSansWeb(FaNum)_Light.eot');
    src: url('../fonts/persian/IRANSansWeb(FaNum)_Light.eot?#iefix') format('embedded-opentype'), url('../fonts/persian/IRANSansWeb(FaNum)_Light.woff2') format('woff2'), url('../fonts/persian/IRANSansWeb(FaNum)_Light.woff') format('woff'), url('../fonts/persian/IRANSansWeb(FaNum)_Light.ttf') format('truetype');
}
