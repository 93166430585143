@charset "UTF-8";
@font-face {
  font-family: IRANSans-web;
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/persian/IRANSansWeb(FaNum)_Bold.eot");
  src: url("../fonts/persian/IRANSansWeb(FaNum)_Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/persian/IRANSansWeb(FaNum)_Bold.woff2") format("woff2"), url("../fonts/persian/IRANSansWeb(FaNum)_Bold.woff") format("woff"), url("../fonts/persian/IRANSansWeb(FaNum)_Bold.ttf") format("truetype"); }

@font-face {
  font-family: IRANSans-web;
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/persian/IRANSansWeb(FaNum)_Light.eot");
  src: url("../fonts/persian/IRANSansWeb(FaNum)_Light.eot?#iefix") format("embedded-opentype"), url("../fonts/persian/IRANSansWeb(FaNum)_Light.woff2") format("woff2"), url("../fonts/persian/IRANSansWeb(FaNum)_Light.woff") format("woff"), url("../fonts/persian/IRANSansWeb(FaNum)_Light.ttf") format("truetype"); }

body {
  background: #fff repeat;
  margin-bottom: 0;
  margin: 0;
  padding: 0;
  font-family: "IRANSans-web"; }

.modal {
  width: 500px !important;
  max-width: 500px !important;
  height: 500px !important;
  direction: rtl !important;
  text-align: right !important;
  padding: 0 !important; }

.modal-custom img {
  width: 100%;
  height: auto; }

a {
  text-decoration: none; }
  a:hover {
    text-decoration: none; }

.slick-next {
  right: 25px !important;
  z-index: 1000; }

.slick-prev {
  left: 25px !important;
  z-index: 1000; }

.slicknav_menu {
  display: none; }
  @media screen and (max-width: 780px) {
    .slicknav_menu {
      width: 100%;
      position: fixed;
      z-index: 999999999;
      display: block;
      direction: rtl; } }

#menu {
  display: none; }

header {
  background: url("../../images/header-bg.jpg") no-repeat;
  padding-top: 10px;
  border-top: 10px solid #75529b; }
  @media screen and (max-width: 780px) {
    header {
      border-bottom: 1px solid #53468f; } }
  header div.content {
    width: 1100px;
    margin: 0 auto; }
    @media screen and (max-width: 780px) {
      header div.content {
        width: 100%; } }
    @media screen and (max-width: 780px) {
      header div.content {
        width: 100%;
        padding-top: 50px; } }
  @media screen and (max-width: 780px) {
    header {
      width: 100%; } }
  header div.left {
    width: 49%;
    display: inline-block; }
    @media screen and (max-width: 780px) {
      header div.left {
        width: 100%;
        text-align: center; } }
  header div.right {
    width: 50%;
    display: inline-block;
    text-align: right;
    direction: rtl; }
    @media screen and (max-width: 780px) {
      header div.right {
        width: 100%;
        text-align: center; } }
  header nav {
    width: 100%;
    height: 50px;
    background: #ad5bc6;
    margin-top: 0px; }
    @media screen and (max-width: 780px) {
      header nav {
        display: none; } }
    header nav ul {
      direction: rtl;
      margin: 0;
      padding: 0;
      float: right; }
      header nav ul li {
        margin-top: 10px;
        display: inline-block;
        margin-left: 33px; }
        header nav ul li a {
          font-size: 14px;
          color: white;
          transition: 0.5s; }
          header nav ul li a:hover {
            color: #f177d8;
            transition: 0.5s; }
      header nav ul li.has-submenu div.submenu {
        display: none;
        z-index: 100000000;
        width: 200px;
        min-height: 60px;
        background: rgba(173, 91, 198, 0.9);
        position: absolute;
        transition: 1s;
        border-radius: 3px;
        padding: 10px;
        padding-top: 3px;
        padding-bottom: 3px; }
        header nav ul li.has-submenu div.submenu ul li {
          padding-left: 10px;
          padding-top: 10px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.15);
          width: 100%;
          padding-bottom: 5px; }
          header nav ul li.has-submenu div.submenu ul li:last-child {
            border-bottom: 0; }
          header nav ul li.has-submenu div.submenu ul li a:hover {
            color: #392179;
            padding-right: 10px; }
      header nav ul li.has-submenu:hover div.submenu {
        display: block;
        transition: 1s; }
    header nav img {
      float: left;
      margin-top: 15px; }

div#slider img {
  width: 100%;
  height: 100%; }

div#news-section {
  width: 100%;
  min-height: 250px; }
  div#news-section div.content {
    width: 1100px;
    margin: 0 auto; }
    @media screen and (max-width: 780px) {
      div#news-section div.content {
        width: 100%; } }
    div#news-section div.content h3 {
      width: 100%;
      text-align: center;
      color: #75529b;
      border-bottom: 1px solid;
      padding-bottom: 10px; }
    div#news-section div.content div.news-content {
      width: 100%;
      padding-top: 10px; }
      div#news-section div.content div.news-content .col-1, div#news-section div.content div.news-content .col-2 {
        width: 38%;
        display: inline-block;
        direction: rtl; }
        @media screen and (max-width: 780px) {
          div#news-section div.content div.news-content .col-1, div#news-section div.content div.news-content .col-2 {
            width: 98%; } }
        div#news-section div.content div.news-content .col-1 .news-img, div#news-section div.content div.news-content .col-2 .news-img {
          width: 30%;
          float: left; }
        div#news-section div.content div.news-content .col-1 .news-text, div#news-section div.content div.news-content .col-2 .news-text {
          width: 67%;
          color: #75529b; }
          div#news-section div.content div.news-content .col-1 .news-text a, div#news-section div.content div.news-content .col-2 .news-text a {
            color: #75529b;
            font-size: 13px; }
          div#news-section div.content div.news-content .col-1 .news-text h5:before, div#news-section div.content div.news-content .col-2 .news-text h5:before {
            content: '● '; }
          div#news-section div.content div.news-content .col-1 .news-text p, div#news-section div.content div.news-content .col-2 .news-text p {
            font-size: 14px;
            text-align: justify; }
        div#news-section div.content div.news-content .col-1 .news-img, div#news-section div.content div.news-content .col-1 .news-text, div#news-section div.content div.news-content .col-2 .news-img, div#news-section div.content div.news-content .col-2 .news-text {
          display: inline-block; }
      div#news-section div.content div.news-content .col-bet {
        width: 19%;
        display: inline-block;
        text-align: center;
        padding: 10px; }
        @media screen and (max-width: 780px) {
          div#news-section div.content div.news-content .col-bet {
            display: none; } }
        div#news-section div.content div.news-content .col-bet img {
          width: 70%; }

div#product-new {
  width: 100%; }
  div#product-new div.content {
    width: 1100px;
    margin: 0 auto; }
    @media screen and (max-width: 780px) {
      div#product-new div.content {
        width: 100%; } }
    div#product-new div.content h4 {
      width: 100%;
      text-align: center;
      color: #b7d301;
      border-bottom: 1px solid;
      padding-bottom: 10px; }
    div#product-new div.content div.product {
      width: 100%;
      min-height: 740px; }
      div#product-new div.content div.product .col-1, div#product-new div.content div.product .col-2, div#product-new div.content div.product .col-3 {
        float: left;
        width: 33.1%;
        height: 500px; }
        div#product-new div.content div.product .col-1 .product-item, div#product-new div.content div.product .col-2 .product-item, div#product-new div.content div.product .col-3 .product-item {
          overflow: hidden; }
        div#product-new div.content div.product .col-1 a, div#product-new div.content div.product .col-2 a, div#product-new div.content div.product .col-3 a {
          cursor: pointer; }
        div#product-new div.content div.product .col-1 img, div#product-new div.content div.product .col-2 img, div#product-new div.content div.product .col-3 img {
          width: 100%; }
        div#product-new div.content div.product .col-1 .cap-overlay, div#product-new div.content div.product .col-2 .cap-overlay, div#product-new div.content div.product .col-3 .cap-overlay {
          background: rgba(0, 0, 0, 0.4); }
          div#product-new div.content div.product .col-1 .cap-overlay h5, div#product-new div.content div.product .col-1 .cap-overlay p, div#product-new div.content div.product .col-2 .cap-overlay h5, div#product-new div.content div.product .col-2 .cap-overlay p, div#product-new div.content div.product .col-3 .cap-overlay h5, div#product-new div.content div.product .col-3 .cap-overlay p {
            padding: 10px;
            color: #fff;
            direction: rtl; }
      div#product-new div.content div.product .col-1, div#product-new div.content div.product .col-2, div#product-new div.content div.product .col-3 {
        padding: 0px !important; }
      div#product-new div.content div.product .col-3, div#product-new div.content div.product .col-2 {
        margin-left: 1px; }
      div#product-new div.content div.product .col-1 .product-item-0 img, div#product-new div.content div.product .col-3 .product-item-0 img {
        height: 500px; }
      div#product-new div.content div.product .col-1 .product-item-1 img, div#product-new div.content div.product .col-3 .product-item-1 img {
        height: 235px; }
      div#product-new div.content div.product .col-2 .product-item-0 img {
        height: 235px; }
      div#product-new div.content div.product .col-2 .product-item-1 img {
        height: 500px; }

div#product-set {
  width: 100%; }
  div#product-set div.header {
    width: 100%;
    height: 50px; }
    div#product-set div.header h3 {
      width: 100%;
      text-align: center;
      color: #75529b;
      border-bottom: 1px solid;
      padding-bottom: 10px; }
  div#product-set div.content {
    width: 1100px;
    margin: 0 auto;
    direction: rtl;
    min-height: 320px;
    margin-top: 40px;
    overflow: hidden; }
    @media screen and (max-width: 780px) {
      div#product-set div.content {
        width: 100%; } }
    @media screen and (max-width: 780px) {
      div#product-set div.content {
        text-align: center;
        width: 60%; } }
    div#product-set div.content .item-set {
      overflow: hidden;
      height: 260px;
      transition: 1s; }
      div#product-set div.content .item-set .info {
        z-index: 10000000;
        width: 260px;
        margin: -63px 0px 0px 0px;
        overflow: hidden; }
        div#product-set div.content .item-set .info div {
          z-index: 20000000;
          background: rgba(117, 82, 155, 0.7);
          display: inline-block;
          width: 250px;
          color: #fff;
          padding: 5px; }
        div#product-set div.content .item-set .info h4 {
          margin: 0;
          padding: 0;
          width: 100%;
          text-align: center;
          height: 100px; }

div#social {
  width: 100%;
  min-height: 470px;
  background: url("../../images/back-insta.png");
  background-size: cover; }
  @media screen and (max-width: 780px) {
    div#social {
      display: none; } }
  div#social .content {
    width: 1100px;
    margin: 0 auto;
    padding-top: 2.5%; }
    @media screen and (max-width: 780px) {
      div#social .content {
        width: 100%; } }
    div#social .content .col-left {
      width: 40%;
      float: left; }
      div#social .content .col-left .insta-logo {
        padding-top: 5%;
        float: left; }
      div#social .content .col-left .insta-today {
        width: 300px;
        float: right;
        overflow: hidden; }
        div#social .content .col-left .insta-today .info {
          margin: -41px 0px 0px 0px; }
          div#social .content .col-left .insta-today .info span {
            background: #75529b;
            width: 405px;
            display: inline-block;
            margin: 2px 2px 0px 0px;
            padding: 5px;
            font-size: 12px;
            color: #fff; }
    div#social .content .col-right {
      width: 40%;
      float: right; }
      div#social .content .col-right .col-content {
        margin-left: 40px;
        margin-right: 40px; }
        div#social .content .col-right .col-content .newsletter {
          width: 100%;
          text-align: center; }
          div#social .content .col-right .col-content .newsletter p {
            font-weight: bold;
            font-size: 15px;
            direction: rtl; }
          div#social .content .col-right .col-content .newsletter input {
            width: 90%;
            padding: 10px;
            border: 1px solid #75529b; }
          div#social .content .col-right .col-content .newsletter button {
            color: #fff;
            margin-top: 10px;
            background: #75529b;
            padding: 5px;
            font-family: "IRANSans-web";
            font-size: 14px;
            width: 100px;
            border: 0;
            cursor: pointer;
            opacity: 1;
            transition: 1s; }
            div#social .content .col-right .col-content .newsletter button:hover {
              opacity: 0.7; }

footer.main {
  width: 100%;
  min-height: 360px;
  background: white;
  border-top: 7px solid #75529b; }
  footer.main div.content {
    padding-top: 30px;
    width: 1100px;
    margin: 0 auto; }
    @media screen and (max-width: 780px) {
      footer.main div.content {
        width: 100%; } }
    @media screen and (max-width: 780px) {
      footer.main div.content #quick-access {
        display: none; } }
    footer.main div.content #map, footer.main div.content #quick-access, footer.main div.content #contact {
      float: left;
      direction: rtl; }
      @media screen and (max-width: 780px) {
        footer.main div.content #map, footer.main div.content #quick-access, footer.main div.content #contact {
          width: 95% !important;
          float: none; } }
      footer.main div.content #map h3, footer.main div.content #quick-access h3, footer.main div.content #contact h3 {
        background: url("../../images/bullet-footer.png") no-repeat;
        background-position: right;
        background-size: 10px;
        padding-right: 15px;
        color: #75529b;
        margin: 0;
        margin-top: 5px;
        font-size: 16px; }
      footer.main div.content #map ul, footer.main div.content #quick-access ul, footer.main div.content #contact ul {
        margin: 0;
        padding: 0; }
        footer.main div.content #map ul li a, footer.main div.content #quick-access ul li a, footer.main div.content #contact ul li a {
          color: #b364e1;
          font-size: 13px;
          transition: 1s; }
          footer.main div.content #map ul li a:hover, footer.main div.content #quick-access ul li a:hover, footer.main div.content #contact ul li a:hover {
            color: rgba(179, 100, 225, 0.8); }
    footer.main div.content #map {
      width: 40%;
      height: 330px; }
    footer.main div.content #quick-access {
      width: 20%;
      margin-top: 65px;
      padding-left: 10px; }
      footer.main div.content #quick-access ul {
        margin-top: 5px; }
        footer.main div.content #quick-access ul li {
          border-bottom: 1px solid #b364e1;
          margin-bottom: 5px;
          padding-bottom: 5px;
          margin-right: 20px;
          margin-left: 30px;
          list-style: none; }
          footer.main div.content #quick-access ul li:before {
            content: '- ';
            color: rgba(255, 255, 255, 0.7); }
          footer.main div.content #quick-access ul li:last-child {
            border-bottom: 0; }
    footer.main div.content #contact {
      width: 30%; }
      footer.main div.content #contact ul li {
        color: #6d6c6c;
        font-size: 13px;
        margin-bottom: 5px;
        padding-left: 5px;
        list-style: none;
        min-height: 35px;
        padding-right: 40px;
        padding-top: 5px;
        margin-top: 10px; }
      footer.main div.content #contact ul li.address {
        background: url("../../images/footer-icon-address.png") no-repeat;
        background-position: right; }
      footer.main div.content #contact ul li.tell {
        background: url("../../images/footer-icon-tel.png") no-repeat;
        background-position: right; }
      footer.main div.content #contact ul li.email {
        background: url("../../images/footer-icon-email.png") no-repeat;
        background-position: right; }

#other-page {
  width: 100%;
  min-height: 500px; }
  @media screen and (max-width: 780px) {
    #other-page {
      width: 93%;
      padding-left: 15px; } }
  #other-page .content {
    width: 1100px;
    margin: 0 auto;
    direction: rtl; }
    @media screen and (max-width: 780px) {
      #other-page .content {
        width: 100%; } }
    #other-page .content h4 {
      color: #75529b;
      border-bottom: 1px solid #75529b;
      padding-bottom: 10px;
      font-size: 17px; }
    #other-page .content section {
      font-size: 14px;
      width: 100%;
      margin-top: 10px; }
      @media screen and (max-width: 780px) {
        #other-page .content section img {
          width: 100% !important; } }
    #other-page .content section.news-page ul li {
      list-style: none;
      width: 100%;
      display: inline-block; }
      #other-page .content section.news-page ul li .news-img {
        float: right;
        margin-left: 15px; }
      #other-page .content section.news-page ul li .news-text p {
        text-align: justify; }

#other-page.products-list ul {
  padding: 0;
  margin: 0; }
  #other-page.products-list ul li {
    display: inline-block;
    width: 24%; }
    #other-page.products-list ul li img {
      width: 100%; }

#other-page.products-list h4 {
  background: url(../../images/product-bred.png) no-repeat;
  background-size: 265px 38px;
  background-position: right;
  border-bottom: 0;
  color: #fff;
  padding-top: 7px;
  padding-right: 30px;
  font-size: 17px; }

#other-page.is-contact img#contact-cover {
  width: 100%; }
  @media screen and (max-width: 780px) {
    #other-page.is-contact img#contact-cover {
      display: none; } }

#other-page.is-contact .contact-content {
  width: 100%;
  min-height: 400px;
  display: inline-block; }
  #other-page.is-contact .contact-content .col {
    width: 49%;
    float: right; }
    @media screen and (max-width: 780px) {
      #other-page.is-contact .contact-content .col {
        width: 100%; } }
    #other-page.is-contact .contact-content .col p {
      color: #ad5bc6; }
    #other-page.is-contact .contact-content .col ul {
      padding: 0;
      margin: 0; }
      #other-page.is-contact .contact-content .col ul li {
        list-style: none;
        padding-right: 40px;
        padding-bottom: 15px;
        padding-top: 15px; }
      #other-page.is-contact .contact-content .col ul li.address {
        background: url("../../images/contact-us-icon-address.png") no-repeat;
        background-position: right; }
      #other-page.is-contact .contact-content .col ul li.tell {
        background: url("../../images/contact-us-icon-tell.png") no-repeat;
        background-position: right; }
      #other-page.is-contact .contact-content .col ul li.mobile {
        background: url("../../images/contact-us-icon-mob.png") no-repeat;
        background-position: right; }
      #other-page.is-contact .contact-content .col ul li.email {
        background: url("../../images/contact-us-icon-mail.png") no-repeat;
        background-position: right; }
      #other-page.is-contact .contact-content .col ul li.instagram {
        background: url("../../images/contact-us-icon-insta.png") no-repeat;
        background-position: right;
        direction: ltr; }
        #other-page.is-contact .contact-content .col ul li.instagram a {
          color: #000; }
      #other-page.is-contact .contact-content .col ul li.telegram {
        background: url("../../images/contact-us-icon-telegram.png") no-repeat;
        background-position: right;
        direction: ltr; }
        #other-page.is-contact .contact-content .col ul li.telegram a {
          color: #000; }
    #other-page.is-contact .contact-content .col span#form-info {
      width: 100%;
      text-align: center;
      color: #ad5bc6;
      padding-right: 54px; }
      #other-page.is-contact .contact-content .col span#form-info:before {
        content: '* ';
        color: #ad5bc6; }
    #other-page.is-contact .contact-content .col form {
      width: 100%;
      margin-top: 10px; }
      #other-page.is-contact .contact-content .col form div.form {
        width: 99%;
        margin-bottom: 10px;
        display: inline-flex; }
        #other-page.is-contact .contact-content .col form div.form label {
          width: 30%;
          display: inline;
          margin-bottom: 5px;
          margin-top: 10px;
          float: right;
          text-align: left;
          padding-left: 15px; }
        #other-page.is-contact .contact-content .col form div.form label.required:before {
          content: '* ';
          color: red;
          font-size: 14px; }
        #other-page.is-contact .contact-content .col form div.form input, #other-page.is-contact .contact-content .col form div.form textarea {
          width: 69%;
          padding: 3px;
          border: 1px solid #ad5bc6;
          display: inline;
          float: left;
          font-size: 14px; }
        #other-page.is-contact .contact-content .col form div.form input {
          height: 30px; }
        #other-page.is-contact .contact-content .col form div.form textarea {
          resize: none;
          height: 100px; }
      #other-page.is-contact .contact-content .col form #send-message {
        background: #963db1;
        font-family: "IRANSans-web";
        font-size: 13px;
        width: 100px;
        color: #fff;
        border: 1px solid #fff;
        cursor: pointer;
        transition: 1s;
        margin-right: 81%; }
        #other-page.is-contact .contact-content .col form #send-message:hover {
          transition: 1s;
          opacity: 0.7; }

footer.last {
  width: 100%;
  height: 30px;
  background: #570c82; }
  @media screen and (max-width: 780px) {
    footer.last {
      text-align: center; } }
  footer.last .content {
    width: 1100px;
    margin: 0 auto; }
    @media screen and (max-width: 780px) {
      footer.last .content {
        width: 100%; } }
    footer.last .content p {
      font-size: 12px;
      margin: 0;
      padding-top: 7px;
      padding-left: 10px;
      font-family: arial;
      color: rgba(255, 255, 255, 0.7); }
