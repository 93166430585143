@import "_font";

$color-title: rgb(190, 19, 1);
$color-text: #fff;
$color-button: rgb(209, 34, 42);
$color-box-background: rgba(255, 255, 255, 0.7);
$color-border: rgb(74, 62, 52);
$font-title: 'IRANSans-web';
$font-text: 'IRANSans-web';
$mobile-size: 780px;

body {
  background: #fff repeat;
  margin-bottom: 0;
  margin: 0;
  padding: 0;
  font-family: $font-title;
}

.modal {
  width: 500px !important;
  max-width: 500px !important;
  height: 500px !important;
  direction: rtl !important;
  text-align: right !important;
  padding: 0 !important;
}

.modal-custom {
  img {
    width: 100%;
    height: auto;
  }
}

@mixin content {
  width: 1100px;
  margin: 0 auto;
  @media screen and (max-width: $mobile-size) {
    width: 100%;
  }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.slick-next {
  right: 25px !important;
  z-index: 1000;
}

.slick-prev {
  left: 25px !important;
  z-index: 1000;
}

.slicknav_menu {
  display: none;
  @media screen and (max-width: $mobile-size) {
    width: 100%;
    position: fixed;
    z-index: 999999999;
    display: block;
    direction: rtl;
  }
}

#menu {
  display: none;
}

header {
  @media screen and (max-width: $mobile-size) {
    border-bottom: 1px solid #53468f;
  }
  div.content {
    @include content;
    @media screen and (max-width: $mobile-size) {
      width: 100%;
      padding-top: 50px;
    }
  }
  @media screen and (max-width: $mobile-size) {
    width: 100%;
  }

  background: url("../../images/header-bg.jpg") no-repeat;

  padding-top: 10px;
  border-top: 10px solid #75529b;

  div.left {
    width: 49%;
    display: inline-block;
    @media screen and (max-width: $mobile-size) {
      width: 100%;
      text-align: center;
    }
  }

  div.right {
    width: 50%;
    display: inline-block;
    text-align: right;
    direction: rtl;
    @media screen and (max-width: $mobile-size) {
      width: 100%;
      text-align: center;
    }
  }

  nav {
    width: 100%;
    height: 50px;
    background: #ad5bc6;
    margin-top: 0px;
    @media screen and (max-width: $mobile-size) {
      display: none;
    }
    ul {
      direction: rtl;
      margin: 0;
      padding: 0;
      float: right;
      li {
        margin-top: 10px;
        display: inline-block;
        margin-left: 33px;
        a {
          font-size: 14px;
          color: rgba(255, 255, 255, 1);
          transition: 0.5s;
          &:hover {
            color: #f177d8;
            transition: 0.5s;
          }
        }
      }
      li.has-submenu {
        div.submenu {
          display: none;
          z-index: 100000000;
          width: 200px;
          min-height: 60px;
          background: rgba(173, 91, 198, 0.9);
          position: absolute;
          transition: 1s;
          border-radius: 3px;
          padding: 10px;
          padding-top: 3px;
          padding-bottom: 3px;
          ul {
            li {
              padding-left: 10px;
              padding-top: 10px;
              border-bottom: 1px solid rgba(255, 255, 255, 0.15);
              width: 100%;
              padding-bottom: 5px;
              &:last-child {
                border-bottom: 0;
              }
              a {
                &:hover {
                  color: #392179;
                  padding-right: 10px;
                }
              }
            }
          }
        }
        &:hover {
          div.submenu {
            display: block;
            transition: 1s;
          }
        }
      }
    }
    img {
      float: left;
      margin-top: 15px;
    }
  }
}

div#slider {
  img {
    width: 100%;
    height: 100%;
  }
}

div#news-section {
  width: 100%;
  min-height: 250px;
  div.content {
    @include content;
    h3 {
      width: 100%;
      text-align: center;
      color: #75529b;
      border-bottom: 1px solid;
      padding-bottom: 10px;
    }
    div.news-content {
      width: 100%;
      padding-top: 10px;
      .col-1, .col-2 {
        width: 38%;
        @media screen and (max-width: $mobile-size) {
          width: 98%;
        }
        display: inline-block;
        direction: rtl;
        .news-img {
          width: 30%;
          float: left;
        }
        .news-text {
          width: 67%;
          color: #75529b;
          a {
            color: #75529b;
            font-size: 13px;
          }
          h5 {
            &:before {
              content: '● ';

            }
          }
          p {
            font-size: 14px;
            text-align: justify;
          }
        }
        .news-img, .news-text {
          display: inline-block;
        }
      }
      .col-bet {
        @media screen and (max-width: $mobile-size) {
          display: none;
        }
        width: 19%;
        display: inline-block;
        text-align: center;
        img {
          width: 70%;
        }
        padding: 10px;
      }
    }
  }
}

div#product-new {
  width: 100%;
  div.content {
    @include content;
    h4 {
      width: 100%;
      text-align: center;
      color: #b7d301;
      border-bottom: 1px solid;
      padding-bottom: 10px;
    }

    div.product {
      width: 100%;
      min-height: 740px;

      .col-1, .col-2, .col-3 {
        float: left;
        width: 33.1%;
        height: 500px;
        .product-item {
          overflow: hidden;
        }
        a {
          cursor: pointer;
        }
        img {
          width: 100%;
        }
        .cap-overlay {
          background: rgba(0, 0, 0, 0.4);
          h5, p {
            padding: 10px;
            color: #fff;
            direction: rtl;
          }

        }
        .drop-panel {

        }
      }
      .col-1, .col-2, .col-3 {
        padding: 0px !important;
      }
      .col-3, .col-2 {
        margin-left: 1px;
      }

      .col-1, .col-3 {
        .product-item-0 {
          img {
            height: 500px;
          }
        }
        .product-item-1 {
          img {
            height: 235px;
          }
        }
      }
      .col-2 {
        .product-item-0 {
          img {
            height: 235px;
          }
        }
        .product-item-1 {
          img {
            height: 500px;
          }
        }
      }
    }
  }

}

div#product-set {
  width: 100%;
  div.header {
    width: 100%;
    height: 50px;
    h3 {
      width: 100%;
      text-align: center;
      color: #75529b;
      border-bottom: 1px solid;
      padding-bottom: 10px;
    }
  }
  div.content {
    @include content;
    direction: rtl;
    min-height: 320px;
    margin-top: 40px;
    overflow: hidden;
    @media screen and (max-width: $mobile-size) {
      text-align: center;
      width: 60%;
    }
    .item-set {
      overflow: hidden;
      height: 260px;
      transition: 1s;
      .info {
        z-index: 10000000;
        width: 260px;
        margin: -63px 0px 0px 0px;
        overflow: hidden;
        div {
          z-index: 20000000;
          background: rgba(117, 82, 155, 0.7);
          display: inline-block;
          width: 250px;
          color: #fff;
          padding: 5px;
        }
        h4 {
          margin: 0;
          padding: 0;
          width: 100%;
          text-align: center;
          height: 100px;
        }
      }
    }
  }

}

div#social {
  width: 100%;
  min-height: 470px;
  background: url('../../images/back-insta.png');
  background-size: cover;
  @media screen and (max-width: $mobile-size) {
    display: none;
  }
  .content {
    @include content;
    padding-top: 2.5%;
    .col-left {
      width: 40%;
      float: left;
      .insta-logo {
        padding-top: 5%;
        float: left;
      }
      .insta-today {
        width: 300px;
        float: right;
        overflow: hidden;
        .info {
          margin: -41px 0px 0px 0px;
          span {
            background: rgb(117, 82, 155);
            width: 405px;
            display: inline-block;
            margin: 2px 2px 0px 0px;
            padding: 5px;
            font-size: 12px;
            color: #fff;
          }
        }

      }
    }
    .col-right {
      width: 40%;
      float: right;
      .col-content {
        margin-left: 40px;
        margin-right: 40px;
        .newsletter {
          width: 100%;
          text-align: center;
          p {
            font-weight: bold;
            font-size: 15px;
            direction: rtl;
          }
          input {
            width: 90%;
            padding: 10px;
            border: 1px solid rgb(117, 82, 155);
          }
          button {
            color: #fff;
            margin-top: 10px;
            background: rgb(117, 82, 155);
            padding: 5px;
            font-family: $font-text;
            font-size: 14px;
            width: 100px;
            border: 0;
            cursor: pointer;
            opacity: 1;
            transition: 1s;
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}

footer.main {
  width: 100%;
  min-height: 360px;
  background: rgb(255, 255, 255);
  border-top: 7px solid rgb(117, 82, 155);
  div.content {
    padding-top: 30px;
    @include content;
    #quick-access {
      @media screen and (max-width: $mobile-size) {
        display: none;
      }
    }
    #map, #quick-access, #contact {
      float: left;
      direction: rtl;
      @media screen and (max-width: $mobile-size) {
        width: 95% !important;
        float: none;
      }
      h3 {
        background: url('../../images/bullet-footer.png') no-repeat;
        background-position: right;
        background-size: 10px;
        padding-right: 15px;
        color: rgb(117, 82, 155);
        margin: 0;
        margin-top: 5px;
        font-size: 16px;
      }

      ul {
        margin: 0;
        padding: 0;
        li {
          a {
            color: rgba(179, 100, 225, 1);
            font-size: 13px;
            transition: 1s;
            &:hover {
              color: rgba(179, 100, 225, 0.8);
            }
          }
        }
      }
    }
    #map {
      width: 40%;
      height: 330px;
    }

    #quick-access {
      width: 20%;
      margin-top: 65px;
      padding-left: 10px;
      ul {
        margin-top: 5px;
        li {
          border-bottom: 1px solid rgb(179, 100, 225);
          margin-bottom: 5px;
          padding-bottom: 5px;
          margin-right: 20px;
          margin-left: 30px;
          list-style: none;
          &:before {
            content: '- ';
            color: rgba(255, 255, 255, 0.7);
          }
          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }

    #contact {
      width: 30%;
      ul {
        li {
          color: #6d6c6c;
          font-size: 13px;
          margin-bottom: 5px;
          padding-left: 5px;
          list-style: none;
          min-height: 35px;
          padding-right: 40px;
          padding-top: 5px;
          margin-top: 10px;
        }
        li.address {
          background: url('../../images/footer-icon-address.png') no-repeat;
          background-position: right;
        }
        li.tell {
          background: url('../../images/footer-icon-tel.png') no-repeat;
          background-position: right;
        }
        li.email {
          background: url('../../images/footer-icon-email.png') no-repeat;
          background-position: right;
        }
      }
    }
  }
}

#other-page {
  width: 100%;
  min-height: 500px;
  @media screen and (max-width: $mobile-size) {
    width: 93%;
    padding-left: 15px;
  }
  .content {
    @include content;
    direction: rtl;
    h4 {
      color: #75529b;
      border-bottom: 1px solid #75529b;
      padding-bottom: 10px;
      font-size: 17px;
    }
    section {
      font-size: 14px;
      width: 100%;
      margin-top: 10px;
      img {
        @media screen and (max-width: $mobile-size) {
          width: 100% !important;
        }
      }
    }
    section.news-page {
      ul {
        li {
          list-style: none;
          width: 100%;
          display: inline-block;
          .news-img {
            float: right;
            margin-left: 15px;
          }
          .news-text {
            p {
              text-align: justify;
            }
          }
        }
      }
    }
  }
}

#other-page.products-list {
  ul {
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      width: 24%;
      img {
        width: 100%;
      }
    }
  }
  h4 {
    background: url(../../images/product-bred.png) no-repeat;
    background-size: 265px 38px;
    background-position: right;
    border-bottom: 0;
    color: #fff;
    padding-top: 7px;
    padding-right: 30px;
    font-size: 17px;
  }
}

#other-page.is-contact {
  img#contact-cover {
    width: 100%;
    @media screen and (max-width: $mobile-size) {
      display: none;
    }
  }
  .contact-content {
    width: 100%;
    min-height: 400px;
    display: inline-block;
    .col {
      width: 49%;
      float: right;
      @media screen and (max-width: $mobile-size) {
        width: 100%;
      }
      p {
        color: #ad5bc6;
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          list-style: none;
          padding-right: 40px;
          padding-bottom: 15px;
          padding-top: 15px;
        }
        li.address {
          background: url('../../images/contact-us-icon-address.png') no-repeat;
          background-position: right;
        }
        li.tell {
          background: url('../../images/contact-us-icon-tell.png') no-repeat;
          background-position: right;
        }
        li.mobile {
          background: url('../../images/contact-us-icon-mob.png') no-repeat;
          background-position: right;
        }
        li.email {
          background: url('../../images/contact-us-icon-mail.png') no-repeat;
          background-position: right;
        }
        li.instagram {
          background: url('../../images/contact-us-icon-insta.png') no-repeat;
          background-position: right;
          direction: ltr;
          a {
            color: #000;
          }
        }
        li.telegram {
          background: url('../../images/contact-us-icon-telegram.png') no-repeat;
          background-position: right;
          direction: ltr;
          a {
            color: #000;
          }
        }
      }
      span#form-info {
        width: 100%;
        text-align: center;
        color: #ad5bc6;
        padding-right: 54px;
        &:before {
          content: '* ';
          color: #ad5bc6;
        }
      }
      form {
        width: 100%;
        margin-top: 10px;
        div.form {
          width: 99%;
          margin-bottom: 10px;
          display: inline-flex;
          label {
            width: 30%;
            display: inline;
            margin-bottom: 5px;
            margin-top: 10px;
            float: right;
            text-align: left;
            padding-left: 15px;
          }
          label.required {
            &:before {
              content: '* ';
              color: red;
              font-size: 14px;
            }
          }
          input, textarea {
            width: 69%;
            padding: 3px;
            border: 1px solid #ad5bc6;
            display: inline;
            float: left;
            font-size: 14px;
          }
          input {
            height: 30px;
          }

          textarea {
            resize: none;
            height: 100px;
          }
        }
        #send-message {
          background: darken(#ad5bc6, 10%);
          font-family: $font-title;
          font-size: 13px;
          width: 100px;
          color: #fff;
          border: 1px solid #fff;
          cursor: pointer;
          transition: 1s;
          margin-right: 81%;
          &:hover {
            transition: 1s;
            opacity: 0.7;
          }
        }
      }
    }
  }
}

footer.last {
  width: 100%;
  height: 30px;
  background: rgb(87, 12, 130);
  @media screen and (max-width: $mobile-size) {
    text-align: center;
  }
  .content {
    @include content;

    p {
      font-size: 12px;
      margin: 0;
      padding-top: 7px;
      padding-left: 10px;
      font-family: arial;
      color: rgba(255, 255, 255, 0.7);
    }
  }

}